.error-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    // background-color: blue;
    justify-content: center;
    align-items: center;

    .title {
        text-align: center;
        color: rgb(29, 29, 29);
        font-size: 20px;
        font-weight: bold;
    }

    .message {
        text-align: center;
        font-size: 14px;
        color: rgb(56, 56, 56);
        padding: 5px;
    }

    .image {
    }

    .actions {
    }

    .attributions {
        font-size: 10px;
        padding-top: 20px;
        a {
            color: rgba(240, 240, 240, 0.247);
        }
    }
}
