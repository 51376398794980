.base-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
    gap: 20px;

    .page-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            font-size: 22px;
            font-weight: 400;
        }

        .global-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    .divider {
        width: 100%;
        border-bottom: 1px solid rgba(56, 56, 56, 0.336);
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .page-body {
        .gate-pass-table-container {
            height: 100%;
        }
    }
}
