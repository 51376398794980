.return-gate-pass-form {
    margin-top: 20px;

    .gate-pass-type-selector {
        width: 175px;
    }

    .ant-input-outlined[disabled] {
        color: black;
    }

    .action-container {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        .approve-btn {
            background-color: #1AC587;
            margin-right: 10px;
        }

        .reject-btn {
            background-color: #E93545;
            margin-right: 10px;
        }

        .return-btn {
            background-color: orange;
            margin-right: 10px;
        }
    }
}