.dashboard-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
    gap: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            font-size: 22px;
            font-weight: 400;
        }

        .action-container {
            display: flex;
            flex-direction: row;
            gap: 10px;

            // .export-btn {
            //     background-color: #1AC587;

            //     &:hover {
            //         background-color: #66e2b4;
            //     }
            // }
        }
    }

    .body {
        height: 100%;
        overflow-y: auto;

        .stat-title {
            font-size: 18px;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        .stat-card-items-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }

        @media (max-width: 992px) {
            .stat-card-items-container {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media (max-width: 600px) {
            .stat-card-items-container {
                grid-template-columns: 1fr;
            }
        }
    }
}