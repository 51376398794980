.inactive-gate-pass-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // background-color: white;
    padding: 20px;
    gap: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            font-size: 22px;
            font-weight: 400;
        }

        .action-container {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .export-btn {
                background-color: #1AC587;

                &:hover {
                    background-color: #66e2b4;
                }
            }
        }
    }

    .body {
        .gate-pass-table-container {
            height: 100%;
        }
    }
}