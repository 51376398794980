.user-table {
    .row-actions {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .btn-delete-user {
            background-color: #E93545;

            &:hover {
                background-color: red;
            }
        }

        .btn-upgrade-user {
            background-color: #1AC587;

            &:hover {
                background-color: #66e2b4;
            }
        }
    }

    .user-status-indicator-chip {
        text-align: center;
        width: 80px;
    }

    .action-status-indicator-chip {
        text-align: center;
    }
}