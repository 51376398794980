.gate-pass-download-view-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 5px;

    .header {
        .title {
            font-size: 22px;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .general-details {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .item-details {}

        .general-details-list-item {
            display: flex;
            flex-direction: column;

            .label {
                font-size: 12px;
                font-weight: 600;
            }

            .value {
                font-size: 15px;
            }
        }
    }
}