.edit-gate-pass-form {
    margin-top: 20px;

    .edit-gp-form-date-picker {
        width: 100%;
    }

    .ant-input-outlined[disabled] {
        color: black;
    }

    .ant-select-selection-item {
        color: black;
    }

    .ant-picker-disabled input[disabled] {
        color: black;
    }

    .update-details-btn {
        background-color: #1ac587;
    }

    .form-actions {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        padding: 10px 5px;
    }

    .cancel-btn {
        margin-top: 10px;
    }
}

.item-action-container {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .btn-edit {
        background-color: blue;
    }

    .btn-delete {
        background-color: #e93545;
    }

    .btn-save {
        background-color: green;
    }

    .btn-cancel {
        background-color: #e93545;
    }
}