.generic-timeline-container {
    margin-top: 30px;

    .gate-pass-timeline-label {
        display: flex;
        flex-direction: column;

        .username {
            font-size: 14px;
            font-weight: 600;
        }

        .date {
            font-size: 11px;
        }
    }
}