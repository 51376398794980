.side-menu-container {
    .header-logo-container {
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: white;
        margin: 20px 10px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
    }

    .ant-menu {
        font-size: 13px;
    }

    .user-details {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 50px;
        padding: 10px;
        gap: 8px;

        .user-avatar {
            margin-top: auto;
            margin-bottom: auto;
            background-color: rgb(150, 150, 150);
        }

        .user-data {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            margin-bottom: auto;
            color: white;

            .name {
                width: 135px;
                font-size: 14px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .role {
                font-size: 12px;
            }
        }
    }

    .log-out-btn {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);
        width: 90%;
    }
}

.side-menu-page-container {
    height: 100vh;
    background-color: white;
    padding: 10px;
}