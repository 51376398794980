.view-gate-pass-form {
    margin-top: 20px;

    .gate-pass-no-display {
        font-size: 18px;
        font-weight: 600;
    }

    .gate-pass-type-selector {
        width: 175px;
    }

    .ant-input-outlined[disabled] {
        color: black;
    }

    .action-container {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
    }
}