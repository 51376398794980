.my-gate-passes-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // background-color: white;
    padding: 20px;
    gap: 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            font-size: 22px;
            font-weight: 400;
        }

        .action-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    .body {
        .my-gate-passes-table-container {
            height: 100%;
        }
    }
}

.items-table {
    margin-top: 10px;
}