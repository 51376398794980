.generic-gate-pass-table {
    // max-height: calc(100vh - 220px);
    // overflow: auto;

    .action-container {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .view-btn {}

        .edit-btn {
            background-color: orange;

            &:hover {
                background-color: rgb(246, 187, 78);
            }
        }

        .download-btn {
            background-color: #1AC587;

            &:hover {
                background-color: #66e2b4;
            }
        }

        .delete-btn {
            background-color: #E93545;

            &:hover {
                background-color: red;
            }
        }
    }

    .row-return-date-exceeded {
        background-color: #ffeaec !important;

        .ant-table-cell-row-hover {
            background-color: #f8d7da !important;
        }
    }
}