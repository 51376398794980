.login-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url("../../../public/assets/ygps-log-in-cover.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .login-form {
        max-width: 400px;
        width: 100%;
    }

    .login-form-button {
        width: 100%;
    }

    .password-reset-form-button {
        width: 100%;
    }

    .login-card-title {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;

        .heading {
            font-size: 18px;
            color: #6c6c6c;
        }

        .sub-heading {
            font-size: 22px;
            font-weight: 800;
            color: #6c6c6c;
        }
    }
}