.gate-pass-items-table-container {
    .receive-all-btn {
        margin-top: 10px;
        background-color: #1AC587;
    }
}

.receive-btn {
    background-color: #1AC587;
    margin-right: 10px;
}