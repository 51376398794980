.generic-confirmation-modal {
    &.ant-modal {
        .ant-modal-content {
            padding: 10px;
            width: 400px;

            .ant-modal-close {
            }

            .ant-modal-header {
                padding: 0px 5px;
                border-bottom: 1px solid #4444444b;

                .ant-modal-title {
                    padding-bottom: 5px;

                    .title {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                    }
                }
            }

            .ant-modal-body {
                padding: 0px 5px;
            }
        }
    }
}
