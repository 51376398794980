.stat-card {
    background-color: white;
    border-radius: 5px;
    text-align: center;

    &-USER{
        border: 1px solid #29378D;
    }

    &-APPROVER{
        border: 1px solid #1875BB;
    }

    .card-header {
        background-color: #29378D;
        color: white;
        font-weight: 600;
        font-size: 18px;
        padding: 20px 20px;
    }

    .card-content {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 5px;
        padding: 40px;
    }

    .USER {
        background-color: #29378D;
    }

    .APPROVER {
        background-color: #1875BB;
    }
}